import React, { useState } from 'react';
import { Anchor, Box, Button, Heading, Grommet, Tab, Tabs } from 'grommet';

import JerseyMaskFront from './front.svg';
import JerseyMaskRightSleeve from './right-sleeve.svg';
import JerseyMaskLeftSleeve from './left-sleeve.svg';
import JerseyMaskBack from './back.svg';
import JerseyMaskBackRightSleeve from './back-right-sleeve.svg';
import JerseyMaskBackLeftSleeve from './back-left-sleeve.svg';
import StripesLogo from './stripes-logo.svg';

function Preview(props) {

  const scale = props.scale || 1;
  const onStripeSelected = props.onStripeSelected || (() => {});
  const onSleeveSelected = props.onSleeveSelected || (() => {});

	return (
  	<>
      <div style={{
        width: '100%', 
        height: Math.min(400, scale*400)+'px', 
        overflow: 'hidden',
        borderBottom: scale > 1 ? '1px solid #000' : 'none',
      }}>
    		
        <div style={{
          width: '300px', 
          height: '400px', 
          margin: '0 auto', 
          position: 'relative', 
          transform: 'scale('+scale+')', 
          //transformOrigin: 'center top',
          transformOrigin: scale > 1 ? 'center top' : (0.5*scale*300) + 'px top',
          paddingTop: "1px",
        }}>
    			
          {props.stripes.map((stripe, i) => 
            <div
              key={"stripe-" + i}
              className="stripe" 
              style={{
                background: stripe.color, 
                height: stripe.calculatedHeight+'%', 
                color: '#eee', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                overflow: 'hidden',
                marginLeft: '1px',
                marginRight: '1px',
              }}
              onClick={() => onStripeSelected(i)}
            >
              {/*stripe.calculatedHeight+'%'*/}
            </div>
          )}

          {props.direction == 'front' && (
            <>
            <div style={{position: 'absolute', top: 0, left: 0, width: '300px', height: '400px'}}>
              <img src={JerseyMaskFront} onClick={(evt) => {
                var stripeTargets = document.querySelectorAll('.stripe');
                var newEvt = document.createEvent("MouseEvents");
                newEvt.initMouseEvent("click", true, true, window,
                  evt.detail, evt.screenX, evt.screenY, evt.clientX, evt.clientY, 
                  evt.ctrlKey, evt.altKey, evt.shiftKey, evt.metaKey, 
                  evt.button, evt.relatedTarget);
                stripeTargets.forEach(elem => {
                  let bounds = elem.getBoundingClientRect();
                  let x = evt.clientX;
                  let y = evt.clientY;
                  if(bounds.left < x && x < bounds.right && bounds.top < y && y < bounds.bottom) {
                    elem.dispatchEvent(newEvt)
                  }
                });
              }} />
            </div>

            <div 
              onClick={() => onSleeveSelected('right')} 
              style={{
                position: 'absolute', 
                top: '42px', 
                left: '-50px', 
                width: '78px', 
                height: '155px', 
                background: props.sleeves.right.color
              }}>
                <img style={{marginLeft: "-1px", marginTop: "-1px"}} src={JerseyMaskRightSleeve} />
              </div>
            
            <div 
              onClick={() => onSleeveSelected('left')} 
              style={{
                position: 'absolute', 
                top: '42px', //'41px', 
                right: '-50px', //'-51px', 
                width: '78px', //'80px', 
                height: '155px', //'157px', 
                background: props.sleeves.left.color
              }}>
                <img style={{marginLeft: "-1px", marginTop: "-1px"}} src={JerseyMaskLeftSleeve} />
              </div>
            
            </>
          )}

          {props.direction == 'back' && (
            <>
            <div style={{position: 'absolute', top: 0, left: 0, width: '300px', height: '400px'}}>
              <img src={JerseyMaskBack} onClick={(evt) => {
                var stripeTargets = document.querySelectorAll('.stripe');
                var newEvt = document.createEvent("MouseEvents");
                newEvt.initMouseEvent("click", true, true, window,
                  evt.detail, evt.screenX, evt.screenY, evt.clientX, evt.clientY, 
                  evt.ctrlKey, evt.altKey, evt.shiftKey, evt.metaKey, 
                  evt.button, evt.relatedTarget);
                stripeTargets.forEach(elem => {
                  let bounds = elem.getBoundingClientRect();
                  let x = evt.clientX;
                  let y = evt.clientY;
                  if(bounds.left < x && x < bounds.right && bounds.top < y && y < bounds.bottom) {
                    elem.dispatchEvent(newEvt)
                  }
                });
              }} />
            </div>

            <div 
              onClick={() => onSleeveSelected('left')} 
              style={{
                position: 'absolute', 
                top: '42px', 
                left: '-50px', 
                width: '78px', 
                height: '155px', 
                background: props.sleeves.left.color
              }}>
                <img style={{marginLeft: "-1px", marginTop: "-1px"}} src={JerseyMaskBackLeftSleeve} />
            </div>
            <div 
              onClick={() => onSleeveSelected('right')} 
              style={{
                position: 'absolute', 
                top: '42px', 
                right: '-50px', 
                width: '78px', 
                height: '155px', 
                background: props.sleeves.right.color
              }}>
                <img style={{marginLeft: "-1px", marginTop: "-1px"}} src={JerseyMaskBackRightSleeve} />
            </div>
            </>
          )}

          {props.showLabel && (
            <div 
              style={{
                fontFamily: 'Pecita', 
                position: 'absolute', 
                top: 0, 
                left: '50%', 
                marginLeft: '10px', 
                marginTop: '44px', 
                fontSize: '10px', 
                color: '#000', 
                transform: 'rotate(90deg)', 
                transformOrigin: 'bottom left', 
                textAlign: 'left',
                whiteSpace: 'nowrap',
              }}
            >
              <img src={StripesLogo} width="36px" /> {props.label}
            </div>
          )}
    		
        </div>
      </div> 
  	</>
  );
}

export default Preview;
