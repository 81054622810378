import React, { useEffect, useState } from "react";
import { Box, Button, Form, Spinner, Text } from 'grommet';
import { Alert } from 'grommet-icons';
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";


export default function CheckoutForm(props) {
  
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const submitStripePayment = async () => {

    if(props.onPaymentSubmitted)
      props.onPaymentSubmitted();
    
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: props.returnUrl,
        receipt_email: props.email,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setErrorMessage(error.message);
    } else {
      setErrorMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    if(props.onBeforePaymentSubmitted) {
      props.onBeforePaymentSubmitted()
        .then(() => {
          submitStripePayment();
        })
        .catch(() => {
          setErrorMessage("An unexpected error occurred.");
          setIsLoading(false);
        });
    }
    else {
      submitStripePayment();
    }
  };


  return (
    <Box align="left">
      <Form onSubmit={handleSubmit}>

        <PaymentElement />
        
        <Box margin={{vertical: "16px"}}>
          
          {errorMessage && 
            <Box 
              margin={{vertical: "medium"}} 
              background="status-error" 
              round={true} 
              pad="medium" 
              align="center" 
              direction="row" 
              gap="medium"
            >
              <Alert />
              <Text>{errorMessage}</Text>
            </Box>
          }

          <Button 
            primary 
            type="submit"
            disabled={isLoading || !stripe || !elements || props.disabled} 
            label={isLoading ? "Paying" : "Pay now"} 
            icon={isLoading ? <Spinner size="xsmall" /> : <></>}
          />

        </Box>

      </Form>
    </Box>
  );
}