import React, { useState, useEffect } from "react";

import { httpsCallable } from 'firebase/functions';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Button } from 'grommet';

import { functions } from "./FirebaseApp";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe("pk_live_51LhAOnLqWOcHACedNn3BlqoXYPKUHBFEth2TyBANb3rHRkPwn07hG1eK8xQqn8mNE02vTZ4nMQmCf9fslMobNitW00P4fMAm1P");
//const stripePromise = loadStripe("pk_test_51LhAOnLqWOcHACedPKIiNOZCUmTmWpFEToKWtM484dh6PjBS2qChia7GSlUwjSLsaw4Z1VNb8YiPpaDt9MG3tAVk00Fx0tq39F");


export default function Payment(props) {
	
	const [intentId, setIntentId] = useState(null);
	const [clientSecret, setClientSecret] = useState(null);
	const [cashTotal, setCashTotal] = useState(null);
	const [voucherTotal, setVoucherTotal] = useState(null);
	const returnUrl = "https://configurator.getmystripes.com";
	//const returnUrl = "http://localhost:3000";


	useEffect(() => {
    
    // Create or update PaymentIntent as soon as the page loads
    // But only if we have a valid quantity
    if(props.quantity == '')
    	return;

  	httpsCallable(functions, intentId? 'updatePaymentIntent' : 'createPaymentIntent')({
    	id: intentId,
    	upgrades: props.upgrades, 
    	quantity: props.quantity,
    	shippingCode: props.shippingCode,
    	voucherCode: props.voucherCode,
    	configurationId: props.configuration
    })
    	.then(result => {
	    	setIntentId(result.data.id);
	    	setClientSecret(result.data.clientSecret);
	    	setCashTotal(result.data.amounts.cashTotal);
	    	setVoucherTotal(result.data.amounts.voucherTotal);
    	})
    	.catch(error => {
    		const code = error.code;
		    const message = error.message;
		    const details = error.details;
		    console.error(code, message);
		    // TODO: display error message if creating/updating payment intent fails
    	});
	}, [props.upgrades, props.quantity, props.shippingCode, props.voucherCode]);





	const submitFullVoucherPayment = () => {
		httpsCallable(functions, 'confirmVoucherPayment')({
				voucherCode: props.voucherCode,
				amount: voucherTotal,
	      receiptEmail: props.email,
	      configurationId: props.configuration
	    })
      .then(result => {
      	
      	if(props.onPaymentSubmitted)
      		props.onPaymentSubmitted(clientSecret, 'payment_intent.succeeded');
      	
      	window.location.replace(returnUrl + '?payment_intent_client_secret=' + clientSecret);
      })
      .catch(error => {
      	console.error('Full Voucher Payment Error:', error.message);
      	// TODO: display error message
      });
	}
	
	const submitPartVoucherPayment = () => {
    return httpsCallable(functions, 'validateAndUseVoucher')({
    		voucherCode: props.voucherCode
    	});
  }










	const elementOptions = {
	    clientSecret: clientSecret,
	    appearance: {
		    theme: 'stripe',
		    labels: 'floating',
		},
	};


	let onBeforePaymentSubmitted = {};
	if(voucherTotal > 0)
		onBeforePaymentSubmitted['onBeforePaymentSubmitted'] = () => submitPartVoucherPayment();




	return (
		<>
      {clientSecret && (

      	<>
      	{cashTotal === 0 ? (

      		<Button 
            primary 
            label="Pay with voucher" 
            onClick={() => {
            	submitFullVoucherPayment()
            }} 
          />

      	) : (

	        <Elements options={elementOptions} stripe={stripePromise}>

	          <CheckoutForm 
	          	disabled={props.disabled}
	          	returnUrl={returnUrl}
	          	email={props.email}
	          	onPaymentSubmitted={() => props.onPaymentSubmitted(clientSecret)}
	          	{...onBeforePaymentSubmitted}
	          />

	        </Elements>
        )}
        </>

      )}
    </>
  );
}
