import React, { useState, useEffect, useRef } from 'react';
import { Accordion, AccordionPanel, Anchor, Box, Button, Card, CardBody, CardHeader, CheckBox, FormField, Heading, Grommet, MaskedInput, NameValueList, NameValuePair, RangeInput, Select, Spinner, Tab, Tabs, Text, TextArea, TextInput } from 'grommet';
import { Alert, FormTrash, Radial, RadialSelected, StatusGood, Trash } from 'grommet-icons';
import { HexColorPicker, HexColorInput } from "react-colorful";
import Autocomplete from "react-google-autocomplete";

import { analytics, functions } from "./FirebaseApp";
import { getDatabase, ref, set, update, get } from "firebase/database";
import { getAuth } from "firebase/auth";
import { httpsCallable } from 'firebase/functions';

import Preview from './Preview';
import Payment from './Payment';
import { CountryList } from './CountryList';

import UpgradeSleeves from './upgrade-sleeves.svg';
import UpgradeGripper from './upgrade-gripper.svg';


const ProgressBar = (props) => (
  <Box
    direction='row'
    align='center'
    justify='between'
    background='white'
    pad={{ left: 'small', right: 'small', vertical: 'small' }}
    style={{ zIndex: '1' }}
  >
    <ProgressStep selected={props.step==1} label='Design' onClick={() => props.onStepSelected(1)} />
    <ProgressStep selected={props.step==2} label='Label' onClick={() => props.onStepSelected(2)} />
    <ProgressStep selected={props.step==3} label='Upgrades' onClick={() => props.onStepSelected(3)} />
    <ProgressStep selected={props.step==4} label='Order' onClick={() => props.onStepSelected(4)} />
  </Box>
);

const ProgressStep = (props) => (
  <Anchor 
    size='small'
    color="black"
    icon={props.selected? <RadialSelected size='small' /> : <Radial size='small' />} 
    label={props.label} 
    margin='small'
    onClick={props.onClick} />
);

const ContinueBar = (props) => (
  <Box
    as='footer'
    width='100%'
    direction='column'
    align='center'
    style={{position: 'relative'}}
    justify='center'
    pad={{ left: 'small', right: 'small', vertical: 'medium' }}
    height={{min: 'min-content'}}
  >
    {props.children}
    <Anchor color="#FF4136" alignSelf='end' style={{position: 'absolute', marginRight: "8px"}} label="Start again" size="small" /*icon={<FormTrash size="medium" />}*/ reverse={true} onClick={props.onRestartClick} />
  </Box>
);

const ColorPicker = (props) => {
  const ref = useRef(null);
  const { onClickOutside, visible, color, onChange } = props;
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (visible && ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
        event.stopPropagation();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ onClickOutside ]);

  return (
    <Box ref={ref} style={{position: "absolute", zIndex: 2, marginTop: "-200px", display: visible? "inherit":"none"}}>
      <HexColorPicker 
        color={color} 
        onChange={onChange}  
      />
    </Box> 
  )  
}

const StripeSettings = (props) => {

  const [colorPickerVisible, setColorPickerVisible] = useState(false);

  if(!props.stripe) return false;

  return (
    
      <Card width="medium" background="light-1">
        {/*<CardHeader pad="small"><Text>Editing the <RadialSelected color={props.stripe.color} /> stripe</Text></CardHeader>*/}
        <CardBody pad="small">
          
          <ColorPicker 
            visible={colorPickerVisible} 
            color={props.stripe.color}
            onChange={props.onColorChange}
            onClickOutside={() => setColorPickerVisible(false)} 
          />
          
          <Box flex margin={{horizontal: "small", top: "small", bottom: "medium"}} direction="row" gap="small">
            <div 
              style={{display: "inline-block", width: "32px", height: "32px", background: props.stripe.color, border: "1px solid black", borderRadius: '2px'}}
              onClick={() => setColorPickerVisible(true)}
            ></div>
            <HexColorInput 
              prefixed
              color={props.stripe.color} 
              onChange={props.onColorChange} 
              onClick={() => setColorPickerVisible(true)}
              style={{
                fontSize: "14px",
                height: "28px",
                width: '282px'
              }}
            />
          </Box>
          

          <Box flex direction="row" margin="small" justify='center'>
            <Text size="small" margin={{top: '4px', right: '4px'}}>Width:&nbsp;&nbsp;0%</Text>
            <RangeInput
              min='1'
              max='100'
              color='black'
              value={props.stripe.calculatedHeight}
              onChange={e => props.onHeightChange(e.target.value)}
            />
            <Text size="small" margin={{top: '4px', left: '4px'}}>100%</Text>
          </Box>
          
          <Box flex margin={{vertical: "medium"}} >
            <Button primary color="black" style={{color: "#fff"}} size="small" label="Done" onClick={props.onDoneEditing} />
          </Box>

          <Box flex margin="small" align="center">
            {props.enableDelete && <Anchor color="#FF4136" size="xsmall" onClick={() => props.onDelete()} /*icon={<FormTrash size="small" />} reverse*/ label="Delete stripe" />}
          </Box>

        </CardBody>
      </Card>

  );
};

const SleeveSettings = (props) => {

  const [colorPickerVisible, setColorPickerVisible] = useState(false);

  if(!props.sleeve) return false;

  return (
    
      <Card width="medium" background="light-1">
        {/*<CardHeader pad="small"><Text>{props.label}</Text></CardHeader>*/}
        <CardBody pad="small">
          
          <ColorPicker 
            visible={colorPickerVisible} 
            color={props.sleeve.color}
            onChange={props.onColorChange}
            onClickOutside={() => setColorPickerVisible(false)} 
          />
          
          <Box flex margin="small" direction="row" gap="small">
            <div 
              style={{display: "inline-block", width: "32px", height: "32px", background: props.sleeve.color, border: "1px solid black", borderRadius: '2px'}}
              onClick={() => setColorPickerVisible(true)}
            ></div>
            <HexColorInput 
              prefixed
              color={props.sleeve.color} 
              onChange={props.onColorChange} 
              onClick={() => setColorPickerVisible(true)}
              style={{
                fontSize: "14px",
                height: "28px",
                width: '282px'
              }}
            />
          </Box>
          
          <Box flex margin={{vertical: "medium"}} >
            <Button primary color="black" style={{color: "#fff"}} size="small" label="Done" onClick={props.onDoneEditing} />
          </Box>

        </CardBody>
      </Card>

  );
};








function Configurator(props) {
  
  const [prices, setPrices] = useState(null);

  const [step, setStep] = useState(0);
  
  const [stripes, setStripes] = useState([]);
  const [sleeves, setSleeves] = useState({});
  const [label, setLabel] = useState('');
  const [upgrades, setUpgrades] = useState([]);

  const [selectedStripeIndex, setSelectedStripeIndex] = useState(null);
  const [selectedSleeve, setSelectedSleeve] = useState(null);
  
  const [email, setEmail] = useState('');
  const [newsletterAgree, setNewsletterAgree] = useState(false);
  const [size, setSize] = useState('');
  const [shape, setShape] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState('');
  const [name, setName] = useState('');
  const [manualAddress, setManualAddress] = useState(false);
  const [voucherCode, setVoucherCode] = useState('');
  const [voucherValue, setVoucherValue] = useState(0);
  const [errorMessageVoucher, setErrorMessageVoucher] = useState(null);
  const [successMessageVoucher, setSuccessMessageVoucher] = useState(null);

  const user = getAuth().currentUser;
  const db = getDatabase();


  useEffect(() => {
    get(ref(db, 'configurations/' + user.uid + '/design')).then(snapshot => {

      const design = snapshot.val();
      
      setStripes(calculateStripesLayout(design && design.stripes || [{
          setHeight: 0,
          color: '#ffffff'
        },{
          setHeight: 10,
          color: '#2fc3f0'
        },{
          setHeight: 10,
          color: '#14545D'
        },{
          setHeight: 10,
          color: '#ff6929'
        },{
          setHeight: 0,
          color: '#ffffff'
        }]));
        
      setSleeves(design && design.sleeves || {
          left: {
            color: '#efefef'
          }, 
          right: {
            color: '#efefef'
          }
        });

      setLabel(design && design.label || 'By Ollie');

      setUpgrades(design && design.upgrades || []);

      setStep(1);

    }); 
  }, [db, user]);


  useEffect(() => {
    get(ref(db, 'prices')).then(snapshot => {
      const prices = snapshot.val();
      setPrices(prices);
    }); 
  }, [db]);



  const formatPrice = (priceInPence) => {
    return '£' + (priceInPence/100).toFixed(2);
  }



  const saveAndContinue = () => {
    set(ref(db, 'configurations/' + user.uid), {
      design: {
        stripes: stripes,
        sleeves: sleeves,
        label: label,
        upgrades: upgrades,
      }
    });
    nextStep();
  }

  const nextStep = () => {
    setStep(step => step + 1);
  }

  const toStep = (step) => {
    setStep(step);
  }




  const saveOrderWithIntentAndStatus = (intentClientSecret, intentStatus) => {
    update(ref(db, 'configurations/' + user.uid), {
      order: {
        email: email,
        quantity: quantity,
        size: size,
        shape: shape,
        intentClientSecret: intentClientSecret,
        intentStatus: intentStatus,
        address: address,
        name: name,
        country: country,
        newsletter: newsletterAgree,
      }
    });
  }





  const addStripe = (setHeight = 0) => {
    let newStripes = [...stripes];
    newStripes.splice(-1, 0, {
      id: +new Date(), 
      setHeight: setHeight,
      color: '#ccc'
    });
    setStripes(calculateStripesLayout(newStripes));
  }

  const removeStripe = (i) => {
    let newStripes = [...stripes];
    newStripes.splice(i, 1);
    setStripes(calculateStripesLayout(newStripes));
  }

  const editStripeHeight = (i, newSetHeight) => {
    let newStripes = [...stripes];
    newStripes[i].setHeight = parseInt(newSetHeight);
    setStripes(calculateStripesLayout(newStripes));
  }

  const editStripeColor = (i, newColor) => {
    let newStripes = [...stripes];
    newStripes[i].color = newColor;
    setStripes(newStripes);
  }

  const calculateStripesLayout = (newStripes) => {

    let totalSetHeight = 0;
    let stripeCountNotSet = 0;
    newStripes.forEach(stripe => {
      if(stripe.setHeight > 0)
        totalSetHeight += stripe.setHeight;
      else
        stripeCountNotSet++;
    });

    let remainingHeight = 100 - totalSetHeight;
    let distributedHeight = Math.max(0, remainingHeight / stripeCountNotSet);

    newStripes.forEach(stripe => {
      stripe.calculatedHeight = stripe.setHeight || distributedHeight;
    });

    return newStripes;
  }

  const editSleeveColor = (side, newColor) => {
    let newSleeves = {...sleeves};
    newSleeves[side].color = newColor;
    setSleeves(newSleeves);
  }

  const hasUpgrade = (upgrade) => {
    if(upgrades.find(elem => elem == upgrade) == undefined)
      return false;
    else
      return true;
  }
  
  const addUpgrade = (upgrade) => {
    let newUpgrades = [...upgrades];
    if(!hasUpgrade(upgrade))
      newUpgrades.push(upgrade);
    setUpgrades(newUpgrades);
  }

  const removeUpgrade = (upgrade) => {
    let newUpgrades = [...upgrades];
    let indexToRemove = newUpgrades.findIndex(elem => elem == upgrade);
    newUpgrades.splice(indexToRemove, 1);
    setUpgrades(newUpgrades);
  }


  
  const calculateTotal = (items, voucherValue) => {

    let totalPence = 0;

    if(quantity == '')
      return totalPence;

    if(!items || items == 'jersey') {
      totalPence += prices.jersey;
      upgrades.forEach(upgrade => {
        totalPence += prices[upgrade];
      });
      //totalPence = totalPence * quantity - voucherValue;
      totalPence *= quantity;
    }
    if(!items || items == 'shipping') {
      const shippingCode = getShippingCode();
      if(shippingCode) {
        totalPence += prices.shipping[shippingCode];
      }
    }

    if(voucherValue) {
      totalPence -= voucherValue;
    }

    return Number((totalPence / 100).toFixed(2));
  }

  const getShippingCode = () => {
    switch(country) {
      case '':
        return null;
        break;
      case 'United Kingdom':
        return 'domestic';
        break;
      default:
        return 'international';
    }
  }

  const applyVoucher = () => {
    if(voucherCode != '') {
      get(ref(db, 'vouchers/' + voucherCode)).then(snapshot => {
        const voucherDetails = snapshot.val();
        if(voucherDetails && !voucherDetails.used) {
          setVoucherValue(voucherDetails.value);
          setErrorMessageVoucher(null);
          setSuccessMessageVoucher('Voucher added');
        }
        else {
          setVoucherValue(0);
          setErrorMessageVoucher('Voucher not valid');
          setSuccessMessageVoucher(null);
        }
      });
    }
  }

  const isPaymentDisabled = () => {
    if(
      size == '' ||
      shape == '' ||
      quantity == '' ||
      address == '' ||
      name == '' ||
      email == ''
    ) {
      return true;
    }
    else {
      return false;
    }
  }


  return (
    <>

      <ProgressBar step={step} onStepSelected={toStep} />
      <Box fill align='center' justify='start' overflow="auto">

        {step === 0 ? (

          <Box fill align="center" justify="center">
            <Spinner size="medium" />
          </Box>

        ) : step == 1 ? (
          
          <>
            <Box fill align='center' justify='start' height={{min: "min-content"}}>
              
              <Heading level='1' margin="small" size="small">Design your stripes</Heading>
              <Text size="small" textAlign="center" margin={{horizontal: "16px", bottom: '8px'}}>Tap a stripe to change it or add a new one. You can do the sleeves too.</Text>
              
              <Tabs fill="horizontal" margin={{top: 'small'}} height={{min: "min-content"}}>
                <Tab title="Front">
                  <Box margin={{vertical: "small"}}>
                    <Preview 
                      direction='front' 
                      stripes={stripes} 
                      sleeves={sleeves}
                      onStripeSelected={(stripeIndex) => {
                        setSelectedStripeIndex(stripeIndex);
                        setSelectedSleeve(null);
                      }}
                      onSleeveSelected={(sleeveSide) => {
                        setSelectedStripeIndex(null);
                        setSelectedSleeve(sleeveSide);
                      }}
                    />
                  </Box>
                </Tab>
                <Tab title="Back">
                  <Box margin={{vertical: "small"}}>
                    <Preview
                      direction='back' 
                      stripes={stripes} 
                      sleeves={sleeves}
                      onStripeSelected={(stripeIndex) => {
                        setSelectedStripeIndex(stripeIndex);
                        setSelectedSleeve(null);
                      }}
                      onSleeveSelected={(sleeveSide) => {
                        setSelectedStripeIndex(null);
                        setSelectedSleeve(sleeveSide);
                      }}
                    />
                  </Box>
                </Tab>
              </Tabs>

              <Box fill="vertical" justify='center' align='center' margin={{bottom: 'small'}} height={{min: 'min-content'}}>
              {selectedStripeIndex != null ? (
                <StripeSettings 
                  stripe={stripes[selectedStripeIndex]} 
                  onColorChange={newColor => editStripeColor(selectedStripeIndex, newColor)}
                  onHeightChange={newHeight => editStripeHeight(selectedStripeIndex, newHeight)}
                  enableDelete={0 < selectedStripeIndex && selectedStripeIndex < stripes.length-1}
                  onDelete={() => removeStripe(selectedStripeIndex)}
                  onDoneEditing={() => setSelectedStripeIndex(null)}
                />
              ) : selectedSleeve != null ? (
                <SleeveSettings
                  sleeve={sleeves[selectedSleeve]}
                  onColorChange={newColor => editSleeveColor(selectedSleeve, newColor)}
                  onDoneEditing={() => setSelectedSleeve(null)}
                />
              ) : (
                <Button secondary style={{fontSize: "14px", width: "120px", padding: "4px", textAlign: "center", alignSelf: "center", border: "1px solid black", borderRadius: "20px", marginTop: "4px"}} onClick={() => addStripe(10)}>Add stripe</Button>
              )}
              </Box>

              {selectedStripeIndex == null && selectedSleeve == null && (
                <ContinueBar onRestartClick={props.onRestart}>
                  <Button primary style={{padding: "8px 24px", textAlign: "center", alignSelf: "center", background: "black", color: "white"}} onClick={() => saveAndContinue()}>Save and continue</Button>
                </ContinueBar>
              )}

            </Box>
          </>

        ) : step == 2 ? (

          <>
            <Box fill align='center' justify='start' height={{min: "min-content"}}>
              
              <Heading level='1' margin="small" size="small">Sign your jersey</Heading>
              <Text size="small" textAlign="center" margin={{horizontal: "16px", bottom: '8px'}}>Add your signature or achievement. Leave blank to get just the logo.</Text>
              
              <Box fill="horizontal" margin={{vertical: "medium"}} height={{min: "min-content"}}>
                <Preview 
                  direction='front'
                  stripes={stripes} 
                  sleeves={sleeves}
                  label={label} 
                  showLabel={true}
                  scale='1.6'
                />
              </Box>

              <Box fill="vertical" justify='center' margin={{bottom: 'small'}} height={{min: "min-content"}}>
                <Card width="medium" background="light-1">
                  <CardBody pad="medium" direction="row">
                    <Text size="small" margin={{top: '12px', right: '8px'}}>Label:</Text>
                    <TextInput
                      placeholder="Label"
                      value={label}
                      onChange={e => setLabel(e.target.value)}
                      style={{background: "#fff"}}
                    />
                  </CardBody>
                </Card>
              </Box>
            
              <ContinueBar onRestartClick={props.onRestart}>
                <Button primary style={{padding: "8px 24px", textAlign: "center", alignSelf: "center", background: "black", color: "white"}} onClick={() => saveAndContinue()}>Save and continue</Button>
              </ContinueBar>

            </Box>

          </>

        ) : step == 3 ? (

          <>
            <Box fill align='center' justify='start' height={{min: "min-content"}}>
              
              <Heading level='1' margin="small" size="small">Choose your upgrades</Heading>
              <Text size="small" textAlign="center" margin={{horizontal: "16px", bottom: '8px'}}>Looking to beat your big ride time? Get the aero advantage.</Text>

              <Box fill align="center" margin={{vertical: "medium"}} gap="medium" height={{min: "min-content"}}>
              
                <CheckBox
                  checked={hasUpgrade('sleeves')}
                  label={
                    <Card width="320px">
                      <CardBody pad="small">
                        <Text margin={{vertical: "xsmall", horizontal: "small"}}>Wind tunnel optimised 3D Hex aero sleeves with low profile bonded cuffs</Text>
                        <Text margin={{vertical: "xsmall", horizontal: "small"}} size="small">{formatPrice(prices['sleeves'])}</Text>
                        <img src={UpgradeSleeves} width="80%" style={{alignSelf: 'center'}} />
                      </CardBody>
                    </Card>
                  }
                  onChange={(e) => {
                    if(e.target.checked)
                      addUpgrade('sleeves');
                    else
                      removeUpgrade('sleeves');
                  }}
                />

                <CheckBox
                  checked={hasUpgrade('gripper')}
                  label={
                    <Card width="320px">
                      <CardBody pad="small">
                        <Text margin={{vertical: "xsmall", horizontal: "small"}}>Low profile silicone micro–dot 360º waist band</Text>
                        <Text margin={{vertical: "xsmall", horizontal: "small"}} size="small">{formatPrice(prices['gripper'])}</Text>
                        <img src={UpgradeGripper} width="80%" style={{alignSelf: 'center'}} />
                      </CardBody>
                    </Card>
                  }
                  onChange={(e) => {
                    if(e.target.checked)
                      addUpgrade('gripper');
                    else
                      removeUpgrade('gripper');
                  }}
                />

              </Box>
            
              <ContinueBar onRestartClick={props.onRestart}>
                <Button primary style={{padding: "8px 24px", textAlign: "center", alignSelf: "center", background: "black", color: "white"}} onClick={() => saveAndContinue()}>Save and continue</Button>
              </ContinueBar>

            </Box>

          </>

        ) : (

          <>
            <Box align="center" justify="start">
              
              <Heading level='1' margin="small" size="small">Submit your order</Heading>
              <Text size="small" textAlign="center" margin={{horizontal: "16px", bottom: '8px'}}>We'll send you a design proof before starting production.</Text>

              <Box height={{min: "min-content"}} margin={{top: "medium", bottom: "small"}}>
                <Card width="medium">
                  <CardBody pad="small">

                    <Box direction="row">
                      <Box basis="1/3">
                        <Preview 
                          direction='front'
                          stripes={stripes} 
                          sleeves={sleeves}
                          label={label} 
                          scale='0.2'
                        />
                      </Box>
                      <Box basis="2/3">
                        <FormField label="Size" help={
                          <Text size="xsmall">You may find our sizes are smaller than some other brands. Please check our <Anchor href="https://getmystripes.com/size-guide" size="xsmall" label="Size Guide" />.</Text>
                        }>
                          <Select
                            placeholder="Select size..."
                            options={['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL']}
                            value={size}
                            onChange={({ option }) => setSize(option)}
                          />
                        </FormField>

                        <FormField label="Shape">
                          <Select
                            placeholder="Select shape..."
                            options={['Unisex', 'Womens']}
                            value={shape}
                            onChange={({ option }) => setShape(option)}
                          />
                        </FormField>

                        <FormField label="Quantity">
                          <MaskedInput
                            placeholder="Enter quantity..."
                            mask={[
                              {
                                length: [1, 2],
                                regexp: /^[0-9]{1,2}$/,
                              },
                            ]}
                            value={quantity}
                            onChange={(event) => {setQuantity(event.target.value)}}
                          />
                        </FormField>
                      </Box>
                    </Box>

                    <Heading level="3" margin={{vertical: "medium"}}>Shipping address</Heading>

                    {!manualAddress? (

                      <Box gap="small">
                        <Autocomplete
                          apiKey="AIzaSyCCDgWar6obW2fRhURsGyuYNvdQkyiUVK0"
                          options={{
                            types: ["address"],
                            fields: [/*"formatted_address",*/"address_component"],
                          }}
                          defaultValue={address}
                          onPlaceSelected={(place, inputRef) => {
                            if(place.name == '') {
                              setAddress('');
                              setCountry('');
                            }
                            else {
                              inputRef.value = place.formatted_address;
                              const countryAddressComponent = place.address_components.filter(addressComponent => addressComponent.types.find(type => type == 'country'))
                              const filteredAddressComponents = [];
                              place.address_components.forEach(comp => {
                                if(comp.types.find(type => type == 'street_number' || type == 'route' || type == 'locality' || type == 'postal_town' || type == 'administrative_area_level_2' || type == 'postal_code')) {
                                  filteredAddressComponents.push(comp.long_name);
                                }
                                else if(comp.types.find(type => type == 'administrative_area_level_1')) {
                                  filteredAddressComponents.push(comp.short_name);
                                }
                              });
                              //setAddress(place.formatted_address);
                              setAddress(filteredAddressComponents.join(', '));
                              setCountry(CountryList[countryAddressComponent[0].short_name]);
                              setManualAddress(true);
                            }
                          }}
                          placeholder="Start typing your address..."
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: '18px',
                            padding: '11px',
                            border: '1px solid rgba(0,0,0,0.33)',
                            borderRadius: '4px',
                            fontWeight: 'bold',
                          }}
                        />
                        <Anchor 
                          size="small"
                          label="Enter address manually"
                          onClick={event => setManualAddress(true)}
                        />
                      </Box>

                    ) : (

                      <Box gap="small">
                        <Anchor 
                          size="small"
                          label="Start address search again"
                          onClick={event => setManualAddress(false)}
                        />
                        <TextInput
                          placeholder="Full name"
                          value={name}
                          onChange={event => setName(event.target.value)}
                        />
                        <TextArea
                          placeholder="Address"
                          value={address}
                          onChange={event => setAddress(event.target.value)}
                        />
                        <Select
                          placeholder="Select country..."
                          options={Object.values(CountryList)}
                          value={country}
                          onChange={({ option }) => setCountry(option)}
                        />
                      </Box>

                    )}


                    <Heading level="3" margin={{vertical: "medium"}}>To pay</Heading>

                    <Box direction="row" alignContent="space-between" margin={{vertical: "small"}}>
                      <Box fill><Text>Jersey {quantity > 0 ? '(x ' + quantity + ')' : ''}</Text></Box>
                      <Box width="xsmall"><Text style={{textAlign: 'right'}}>£{calculateTotal('jersey')}</Text></Box>
                    </Box>

                    <Box direction="row" alignContent="space-between" margin={{vertical: "small"}}>
                      <Box fill><Text>{country == '' ? 'Shipping' : `Shipping (${country})`}</Text></Box>
                      <Box width="xsmall"><Text style={{textAlign: 'right'}}>{country == '' ? '—' : `£${calculateTotal('shipping')}`}</Text></Box>
                    </Box>

                    {voucherValue > 0 &&
                      <Box direction="row" alignContent="space-between" margin={{vertical: "small"}}>
                        <Box fill><Text>Voucher</Text></Box>
                        <Box width="xsmall"><Text style={{textAlign: 'right'}}>– £{voucherValue / 100}</Text></Box>
                      </Box>
                    }

                    <Box direction="row" alignContent="space-between" margin={{vertical: "small"}}>
                      <Box fill><Text>Total to pay</Text></Box>
                      <Box width="xsmall"><Text style={{textAlign: 'right'}}>£{calculateTotal(null, voucherValue)}</Text></Box>
                    </Box>


                  </CardBody>
                </Card>
              </Box>

              <Box height={{min: "min-content"}} margin={{top: "medium"}}>
                <Card width="medium" margin={{bottom: "large"}}>
                  <CardBody pad="small">

                    <Heading level="3" margin={{vertical: "small"}}>Email</Heading>
                    <TextInput type="email" placeholder="Your email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <Box margin="small">
                      <CheckBox
                        checked={newsletterAgree}
                        label="Join mailing list?"
                        onChange={(event) => setNewsletterAgree(event.target.checked)}
                      />
                    </Box>
                    
                    <Heading level="3" margin={{top: "medium", bottom: "small"}}>Payment</Heading>
                    <Box>
                      
                      <Accordion>
                        <AccordionPanel label="Got a gift voucher?">
                          <Box pad="small" gap="small">

                            <TextInput type="text" placeholder="Voucher code" value={voucherCode} onChange={(e) => setVoucherCode(e.target.value)} />
                            <Button size="small" label="Apply" onClick={() => applyVoucher()} />

                            {successMessageVoucher && 
                              <Box 
                                margin={{vertical: "medium"}} 
                                background="success" 
                                round={true} 
                                pad="medium" 
                                align="center" 
                                direction="row" 
                                gap="medium"
                              >
                                <StatusGood color="white" />
                                <Text color="white">{successMessageVoucher} – total to pay now £{calculateTotal(null, voucherValue)}</Text>
                              </Box>
                            }

                            {errorMessageVoucher && 
                              <Box 
                                margin={{vertical: "medium"}} 
                                background="error" 
                                round={true} 
                                pad="medium" 
                                align="center" 
                                direction="row" 
                                gap="medium"
                              >
                                <Alert />
                                <Text>{errorMessageVoucher}</Text>
                              </Box>
                            }

                          </Box>
                        </AccordionPanel>
                      </Accordion>

                      <Payment 
                        upgrades={upgrades} 
                        quantity={quantity} 
                        shippingCode={getShippingCode()}
                        voucherCode={voucherValue > 0 ? voucherCode : null}
                        email={email}
                        configuration={user.uid}
                        disabled={isPaymentDisabled()}
                        //onBeforePaymentSubmitted={() => validateAndUseVoucher(voucherCode)}
                        onPaymentSubmitted={(intentClientSecret, initialPaymentStatus = null) => {
                          saveOrderWithIntentAndStatus(intentClientSecret, initialPaymentStatus);
                        }}
                      />
                      
                    </Box>

                  </CardBody>
                </Card>
              </Box>

            </Box>
          </>

        )}

      </Box>
    </>
  );
}

export default Configurator;
