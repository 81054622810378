import React, { useState, useEffect } from 'react';

import { Anchor, Box, Button, Card, CardBody, CardHeader, Grommet, Heading, Image, Paragraph, Spinner, Text } from 'grommet';
import { Alert, StatusGood } from 'grommet-icons';

import Configurator from './Configurator';
import Preview from './Preview';
import StripesLogo from './stripes-logo.svg';

import "./FirebaseApp";
import { getAuth, signInAnonymously } from "firebase/auth";
import { child, equalTo, getDatabase, onValue, orderByChild, query, ref, set, update, get } from "firebase/database";

import CookieConsent from "react-cookie-consent";



const theme = {
  global: {
    colors: {
      brand: '#000',
      focus: '#ffaa00',
      error: '#FF4136',
      success: '#2ECC40'
    },
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
    focus: {
      shadow: {
        size: '0px'
      },
      outline: {
        color: 'focus'
      }
    }
  },
  heading: {
    color: 'black',
    font: {
      family: 'Poppins'
    },
    level: {
      4: {
        font: {
          family: 'Roboto'
        }
      }
    }
  },
  tab: {
    color: 'grey',
    border: {
      color: 'grey'
    }
  },
  rangeInput: {
    thumb: {
      color: 'black'
    }
  },
  accordion: {
    border: {
      color: '#fff'
    }
  }
};



const AppBar = (props) => (
  <Box
    as='header'
    direction='row'
    align='center'
    justify='between'
    background='white'
    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
    //elevation='medium'
    style={{ zIndex: '1' }}
    {...props}
  >
    <Image
      height="32px"
      alignSelf="start"
      fit="contain"
      fill={false}
      src={StripesLogo}
      style={{
        objectPosition: "left top"
      }}
    />
  </Box>
);



function App() {

  const auth = getAuth();
  const db = getDatabase();

  const [initialising, setInitialising] = useState(true);
  const [sessionId, setSessionId] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');


  useEffect(() => {
    signInAnonymously(auth)
      .then(() => {
        setSessionId(auth.currentUser.uid);
        console.log('Session:', auth.currentUser.uid);
        setInitialising(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // TODO: display error page if we can't create a session for some reason
      });
  }, []);

  useEffect(() => {

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    // Check URL and ensure user is signed in before querying database
    if(!clientSecret || !sessionId)
      return;

    //const q = query(ref(db, 'configurations/' + sessionId), orderByChild('order/intentClientSecret'), equalTo(clientSecret));
    //const unsubscribe = onValue(q, (snapshot) => {
    const unsubscribe = onValue(ref(db, 'configurations/' + sessionId), (snapshot) => {
      
      const config = snapshot.val();
      //const configId = Object.keys(config)[0];
      const status = config.order.intentStatus;

      setInitialising(true);

      setEmail(config.order.email);
      setAddress(config.order.address);

      switch (status) {
        case "payment_intent.succeeded":
          setPaymentStatus({
            status: status,
            heading: "Payment complete!",
            icon: <StatusGood size='xlarge' color='success' />
          });
          setInitialising(false);
          break;
        case "payment_intent.payment_failed":
          setPaymentStatus({
            status: status,
            heading: "Payment failed",
            icon: <Alert size='xlarge' color='error' />
          });
          setInitialising(false);
          break;
      }
    });

    return unsubscribe;

  }, [sessionId, window.location.search]);


  const restart = () => {
    auth.signOut();
    window.location.replace('/');
  }


  return (

    <Grommet theme={theme} full>

      <CookieConsent
        cookieName="hasConsent"
        extraCookieOptions={{
          domain: "getmystripes.com"
        }}
        buttonText="Accept"
        declineButtonText="Reject"
        enableDeclineButton={true}
        onAccept={() => {
          window.location.replace('/');
        }}
        style={{
          background: "black",
          fontSize: "14px",
          justifyContent: "center"
        }}
        contentStyle={{
          flex: "none"
        }}
        buttonStyle={{
          border: "2px solid white",
          background: "none",
          color: "#fff",
          borderRadius: 20,
          fontSize: "16px"
        }}
        declineButtonStyle={{
          border: "2px solid white",
          background: "none",
          color: "#fff",
          borderRadius: 20,
          fontSize: "16px",
          marginRight: 0
        }}
      >
        By continuing to use this site, you agree to our <Anchor href="https://getmystripes.com/cookie-policy" label="use of cookies" color="#fff" />.
      </CookieConsent>

      <Box fill>

      {initialising? (

        <Box fill align="center" justify="center">
          <Spinner size="medium" />
        </Box>

      ) : paymentStatus? (

        <>
        <AppBar />
        <Box align="center" margin={{top: 'large', bottom: 'medium'}}>
          {paymentStatus.icon}
          <Heading level='1'>{paymentStatus.heading}</Heading>
        </Box>

        {paymentStatus.status == 'payment_intent.succeeded' ? (
          
          <>
          <Box align="center">
            <Card width="medium" pad="medium">
              <CardHeader><Heading level='4' margin="none">What happens next?</Heading></CardHeader>
              <CardBody>
                <ol>
                  <li>We'll check everything one last time and send you a design proof before starting production.</li>
                  <li>Your jersey is then added into the production queue.</li>
                  <li>After 6–8 weeks it should be ready to ship. We'll be in touch to let you know the exciting news!</li>
                  <li>We'll then ship it to your address at {address}.</li>
                </ol>
                <Text size="small">If there's anything we need to confirm with you during production then we'll email you at <b>{email}</b>.</Text>
              </CardBody>
            </Card>
            <Paragraph><Anchor href="https://getmystripes.com/contact">Send us a message</Anchor> if you have any questions.</Paragraph>
          </Box>
          <Box align="center" margin="large">
            <Button primary label="Create another jersey" onClick={restart} />
          </Box>
          </>
        
        ):(
        
          <>
          <Box align="center">
            <Card width="medium" pad="medium">
              <CardHeader><Heading level='4' margin="none">What should I do?</Heading></CardHeader>
              <CardBody>
                <Paragraph>Please try your payment again, making sure all your details are correct. If this still fails then please try a different card or payment method.</Paragraph>
              </CardBody>
            </Card>
            <Paragraph><Anchor href="https://getmystripes.com/contact">Send us a message</Anchor> if you continue to have issues.</Paragraph>
          </Box>
          </>

        )}
        
        </>

      ) : (
        
        <>
        <AppBar />
        <Configurator onRestart={restart} />
        </>

      )}

      </Box>
    </Grommet>
  );
}

export default App;
