import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getFunctions } from "firebase/functions";

import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";


const firebaseConfig = {
  apiKey: "AIzaSyCkSVLtGeFUuDABQsh5xpeRgF9CrLaqwtc",
  authDomain: "om-stripes.firebaseapp.com",
  projectId: "om-stripes",
  storageBucket: "om-stripes.appspot.com",
  messagingSenderId: "231241374862",
  appId: "1:231241374862:web:735f2f3d06569d5daabddf",
  measurementId: "G-C5KYNZ4D3P",
  databaseURL: "https://om-stripes-default-rtdb.europe-west1.firebasedatabase.app/",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const functions = getFunctions(app);

let analytics;
const cookieValue = getCookieConsentValue('hasConsent');
if(cookieValue && cookieValue == 'true') {
  console.log('Enabling analytics because we have cookie consent');
  analytics = getAnalytics(app);
}

export { analytics, database, functions };
